import React from "react";
import SEO from "../components/SEO";

function RedirectTemplate({ pageContext: { productLink, productOwner } }) {
  React.useEffect(() => {
    window.location.replace(productLink);
  }, [productLink]);

  return (
    <>
      <SEO
        nofollow
        title={
          "Tak for dit besøg hos billigform.dk. Vi tager dig nu videre til forhandleren"
        }
        description={`Tak for dit besøg hos billigform.dk. Vi tager dig nu videre til ${
          productOwner?.[0] || "forhandleren"
        }. Vi håber at se dig hos Billigform igen.`}
      />
      <div className="lg:px-12 pb-4 max-w-4xl m-auto">
        <div className="mx-auto lg:px-12">
          <h1 className="font-bold text-2xl">
            Vi håber at du fandt det du ledte efter!
          </h1>
          <h2 className="mt-4 text-lg">
            Du føres nu videre til{" "}
            <span className="font-bold">
              {productOwner?.[0] || "forhandleren"}
            </span>
            ...
          </h2>
        </div>
      </div>
    </>
  );
}

export default RedirectTemplate;
